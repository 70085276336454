<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="2" title="Workflow Step" icon="" />

    <div class="generalBox shadow-sm border">
        <div class="row">
            <div class="col-12 fw-bold mb-3">Document uploaded for workflow</div>
            <div class="col-lg-12 mb-3 text-primary">
                Agreement.pdf 
                <span class="badge bg-secondary">
                    <Popper class="popperDark" arrow hover content="Total objects">
                        <fa icon="object-ungroup" class="me-1"/>5
                    </Popper>
                </span>
            </div>

            <div class="col-lg-12">
                <table class="table table-borderless">
                    <thead>
                        <tr class="border-0 border-2 border-bottom border-secondary">
                            <th>
                                <div class="row">
                                    <div class="col-lg-6">Settings</div>
                                    <div class="col-8 col-lg-4">Value</div>
                                    <div class="col-4 col-lg-2 text-end"></div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-lg-6">Folder Privacy Setting for Public Viewers</div>
                                    <div class="col-8 col-lg-4"><ProjPrivacy :value="projPrivacy" /></div>
                                    <div class="col-4 col-lg-2 text-end">
                                        <span class="isLink ms-2" data-bs-toggle="modal" data-bs-target="#mdlPrivacy">
                                            <Popper class="popperDark" arrow hover content="Edit folder privacy"><fa icon="pencil" /></Popper>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-lg-6">Signing Mode</div>
                                    <div class="col-8 col-lg-4"><ProjMode :value="projectMode" />{{projectMode}}</div>
                                    <div class="col-4 col-lg-2 text-end">
                                        <span class="isLink ms-1" data-bs-toggle="modal" data-bs-target="#mdlSigningMode">
                                            <Popper class="popperDark" arrow hover content="Edit signing mode">
                                                <fa icon="pencil" />
                                            </Popper>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-lg-6">Running No</div>
                                    <div class="col-8 col-lg-4 text-danger">Running No is compulsory</div>
                                    <div class="col-4 col-lg-2 text-end">
                                        <span class="isLink ms-1" data-bs-toggle="modal" data-bs-target="#mdlRunningNo">
                                            <Popper class="popperDark" arrow hover content="Set running no">
                                                <fa icon="pencil" class="text-danger" />
                                            </Popper>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-lg-6">Allow signee upload attachment</div>
                                    <div class="col-8 col-lg-4">Enabled</div>
                                    <div class="col-4 col-lg-2 text-end">
                                        <span class="isLink ms-1" data-bs-toggle="modal" data-bs-target="#mdlAllowAttachment">
                                            <Popper class="popperDark" arrow hover content="Edit upload attachment">
                                                <fa icon="pencil" />
                                            </Popper>
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div class="col-12"><hr /></div>
            <div class="col-12 text-end">
                <!-- <button class="btn btn-secondary me-2">
                    <fa icon="trash" size="lg" class="me-2" />Delete
                </button> -->
                <button class="btn btn-secondary me-2" @click="$router.push({ path: '/workflow/obj' })">
                    <fa icon="circle-arrow-right" size="lg" class="me-2" />Next
                </button>
            </div>
        </div>
    </div>

    <!-- Modal: Privacy --> 
    <div
        class="modal fade" 
        id="mdlPrivacy"
        tabindex="-1"
        aria-labelledby="Privacy"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Folder privacy</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-2">Folder Privacy Setting for Public Viewers</div>
                    <table>
                        <tr>
                            <td width="30px"><input type="radio" v-model="projPrivacy" value="PUBLIC" @change="updProjPrivacy" data-bs-dismiss="modal" /></td>
                            <td><ProjPrivacy value="PUBLIC" /> Disclose full information</td>
                        </tr>
                        <tr>
                            <td><input type="radio" v-model="projPrivacy" value="PRIVATE" @change="updProjPrivacy" data-bs-dismiss="modal" /></td>
                            <td><ProjPrivacy value="PRIVATE" /> View name, timestamp and status only</td>
                        </tr>
                        <tr>
                            <td><input type="radio" v-model="projPrivacy" value="CONFIDENTIAL" @change="updProjPrivacy" data-bs-dismiss="modal" /></td>
                            <td class="pe-1"><ProjPrivacy value="CONFIDENTIAL" /> View document name and timestamp only</td>
                        </tr>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->
    <!-- Modal: Signing Mode --> 
    <div
        class="modal fade" 
        id="mdlSigningMode"
        tabindex="-1"
        aria-labelledby="SigningMode"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Signing mode</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="text-grey fst-italic mb-3">Simple automation to make your signing process easier</div>
                    <div class="btn-group-vertical" style="width: 100%">
                        <button type="button" class="btn" @click="projectMode = 'SIMPLE'" :class="projectMode === 'SIMPLE' ? 'btn-secondary' : 'btn-outline-secondary'">
                            <table>
                                <tr>
                                    <td class="text-start" width="40px"><fa :icon="['far', 'circle']" size="lg"/></td>
                                    <td class="text-start small">
                                        <div>SIMPLE</div>
                                        <div class="text-grey small">Sign with valid email only.</div>
                                    </td>
                                </tr>
                            </table>
                        </button>
                        <button type="button" class="btn" @click="projectMode = 'KYC'" :class="projectMode === 'KYC' ? 'btn-secondary' : 'btn-outline-secondary'">
                            <table>
                                <tr>
                                    <td class="text-start" width="40px"><fa icon="circle-half-stroke" size="lg"/></td>
                                    <td class="text-start small">
                                        <div>KYC</div>
                                        <div class="text-grey small">Require to verify identity. You may assign signing sequence.</div>
                                    </td>
                                </tr>
                            </table>
                        </button>
                        <button type="button" class="btn" @click="projectMode = 'STRICT'" :class="projectMode === 'STRICT' ? 'btn-secondary' : 'btn-outline-secondary'">
                            <table>
                                <tr>
                                    <td class="text-start" width="40px"><fa icon="circle"  size="lg"/></td>
                                    <td class="text-start small">
                                        <div>STRICT</div>
                                        <div class="text-grey small">You can review the signing process manually, request for footage of signing, upload hardcopy of signed documents later.</div>
                                    </td>
                                </tr>
                            </table>
                        </button>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->
    <!-- Modal: Running No --> 
    <div
        class="modal fade" 
        id="mdlRunningNo"
        tabindex="-1"
        aria-labelledby="RunningNo"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark modal-lg">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Running no</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-2 fw-bold">Set running number for your workflow document.</div>
                        <div class="col-12 col-lg-5">
                            <div class="mb-2">Select to continue with existing running number</div>
                            <table class="table table-hover">
                                <tbody>
                                    <tr v-for="r in arrRunning" :key="r">
                                        <td width="10%"><input type="radio" v-model="runningId" :value="r.prefix" /></td>
                                        <td>{{ r.prefix }}</td>
                                        <td>{{ r.number }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-lg-2 text-center text-middle my-2 fw-bold">OR</div>
                        <div class="col-lg-5">
                            <div class="generalContentBox border">
                                <div class="row">
                                    <div class="col-12 mb-3">
                                        <input type="radio" v-model="isNewRunningNo" value="true" class="me-2" />Create new running number
                                    </div>

                                    <div class="col-12 mb-2">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" v-model="runPrefix" id="runPrefix" placeholder="Prefix">
                                            <label for="runPrefix">Prefix</label>
                                        </div>
                                    </div>

                                    <div class="col-12 mb-2">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" v-model="runInitialNo" id="runInitialNo" placeholder="runInitialNo">
                                            <label for="runInitialNo">Initial Number</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->
    <!-- Modal: Allow attachment --> 
    <div
        class="modal fade" 
        id="mdlAllowAttachment"
        tabindex="-1"
        aria-labelledby="AllowAttachment"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Upload attachment</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <table border="0">
                        <tbody>
                            <tr>
                                <td class="align-top">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" v-model="allowAttachment" value="true" id="eSignee">
                                    </div>
                                </td>
                                <td class="ps-2">Allow signee to upload attachment when approve/reject a document</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->
</template>

<script>
import { ref, inject, onMounted, watch, reactive } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert.vue'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import DropZone from '@/components/DropZone.vue'
import ProjPrivacy from '@/components/ProjPrivacy.vue'
import ProjMode from '@/components/ProjMode.vue'

export default {
    name: 'WorkFlowDoc',
    components: { TopNavigation, Alert, Popper, DropZone, ProjPrivacy, ProjMode },
    setup () {
        const alert = ref([])

        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const projPrivacy = ref('PRIVATE')
        const projectMode = ref('SIMPLE')
        
        const isNewRunningNo = ref(false)
        const runPrefix = ref(null)
        const runInitialNo = ref(null)
        const allowAttachment = ref(false)
        const arrRunning = ref([])

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        onMounted(() => {
            arrRunning.value = [
                {
                    "prefix": "DocId",
                    "number": ""
                },
                {
                    "prefix": "CR_",
                    "number": "23000"
                }
            ]
        })

        return { 
            route, router, alert, closeAlert, 
            projPrivacy, projectMode, isNewRunningNo, runPrefix, runInitialNo, allowAttachment, arrRunning
        }

    }
}
</script>

<style>
</style>